var reviewsTable = $('#reviews-admin').DataTable({
	processing: true,
	serverSide: true,
	stateSave: true,
	columns: [
		{data: "date", className: "text-nowrap"},
		{data: "pharmacy_id"},
		{data: "author"},
		{data: "rating"},
		{data: "comment"},
		{data: "email", visible: false}
	],
	order: [[0, "desc"]]
});

// Show modal on page load
$('.modal-show-on-load').modal('show');

$(".navbar .nav-item").filter(function() {
	var loc = window.location;
	return loc.pathname + loc.search + loc.hash == $("a", this).attr("href");
}).addClass("active");

$(".nav .nav-link").filter(function() {
	var loc = window.location;
	return loc.pathname + loc.search + loc.hash == $(this).attr("href");
}).addClass("active");

$(".nav li a").click(function () {
	reviewsTable.state.clear();
});

$("#reviews-admin").on("click", "tbody tr", function () {
	var reviewId = $(this).data("review-id");
	window.location = "/admin/edit-review.php?id=" + $(this).data("review-id");
});

$("#more-reviews").click(function () {
	page = $('#reviews').data('next-page') || 2;
	$.get(window.location.pathname + '?page=' + page, function(data) {
	    $('#reviews').append(data);
	});
	$('#reviews').data('next-page', page + 1);
});
