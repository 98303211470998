// See https://jqueryvalidation.org/files/demo/bootstrap/index.html

jQuery.validator.setDefaults({
	errorElement: "div",
	errorPlacement: function (error, element) {
		error.addClass("invalid-feedback");
		element.closest(".form-group").append(error);
	},
	highlight: function (element, errorClass, validClass) {
		if ($(element).attr("type") != 'radio') {
			$(element).addClass("is-invalid").removeClass("is-valid");
		}
	},
	unhighlight: function (element, errorClass, validClass) {
		$(element).addClass("is-valid").removeClass("is-invalid");
	}
});

$("form.validate").validate();
